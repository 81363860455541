import { CodeOutlined, DescriptionOutlined, FolderOutlined, MoreVertOutlined } from "@material-ui/icons";
import { Button, Menu } from "../../UI";

const DetailsPanelMenu = ({ selectedItemType, onSelectMenuItem }) => {
  const handleClick = (item) => () => onSelectMenuItem(item);

  const isCodeItem =
    selectedItemType === "api" ||
    selectedItemType === "apicode" ||
    selectedItemType === "workflowcode" ||
    selectedItemType === "taskapi" ||
    selectedItemType === "apicompliance";

  if(!selectedItemType || selectedItemType === "link") return null;

  return (
    <Menu nestedMenuPlacement="left">
      <Menu.Handler>
        <Button color="secondary" rounded={true} size="xs">
          <MoreVertOutlined />
        </Button>
      </Menu.Handler>
      <Menu.List>
        {(isCodeItem || selectedItemType === "workflow" || selectedItemType === "workflowcompliance" || selectedItemType === "workflowterminal" || selectedItemType === "product") && (
          <>
            {isCodeItem && selectedItemType !== "workflowcode" && (
              <Menu.Item onClick={handleClick("cURL")}>
                <CodeOutlined />
                cURL
              </Menu.Item>
            )}
            {selectedItemType !== "product" && (
              <>
                <Menu.Item onClick={handleClick("javascript")}>
                  <CodeOutlined />
                  Javascript Code
                </Menu.Item>
                <Menu.Item onClick={handleClick("python")}>
                  <CodeOutlined />
                  Python Code
                </Menu.Item>
              </>
            )}
            <Menu.Item onClick={handleClick("export-openapi")}>
              <FolderOutlined />
              Export Open API 3.0.0
            </Menu.Item>
          </>
        )}
        <Menu.Item onClick={handleClick("description")}>
          <DescriptionOutlined />
          Description
        </Menu.Item>
        <Menu.Item onClick={handleClick("compliancedescription")}>
          <DescriptionOutlined />
          Compliance...
        </Menu.Item>
      </Menu.List>
    </Menu>
  );
};

export default DetailsPanelMenu;
