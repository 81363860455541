import styled from "styled-components";
import { skeletonLoading } from "./animations";

const StyledSkeleton = styled.div`
  height: ${({ height }) => height};
  border-radius: ${({ borderRadius }) => borderRadius};
  width: ${({ width }) => width};
  opacity: var(--skeleton-opacity);
  animation: ${skeletonLoading} 1s linear infinite alternate;
`;

/**
 * GWOCU's Skeleton component
 *
 * Displays a loading placeholder with customizable dimensions and border radius.
 *
 * @param {Object} props - Component props.
 * @param {string} [className=undefined] - Custom class name to apply to the skeleton element. Default value: `undefined`.
 * @param {string|number} props.height - Height of the skeleton placeholder.
 * @param {string|number} [props.width="100%"] - Width of the skeleton placeholder. Default value: `"100%"`.
 * @param {string} [props.borderRadius="0.5em"] - Border radius of the skeleton placeholder. Default value: `"0.5em"`.
 * @returns {JSX.Element} Styled skeleton placeholder.
 */
const Skeleton = ({ className, height, width = "100%", borderRadius = "0.5em" }) => (
  <StyledSkeleton className={className} height={height} width={width} borderRadius={borderRadius} />
);

export default Skeleton;
