import React, { useState } from 'react';
import axios from "axios";
import Modalworkflowclone from "../modalworkflowclone/Modalworkflowclone";
import { fetchErrorView } from '../fetchErrorView/FetchErrorView.jsx';
import { Button, Form, Icon, InputText, Select, TextEditor, Toolbar } from "../../UI";
import { useAsync } from '../../hooks/useAsync.js';
import { getDecodedBody, encodebody } from "../../utils/utils.js";
import HelpCenterIcon from '@mui/icons-material/HelpCenter';

const statusOptions = ["Private","Public"];

function Workflowview({ clientNr, explorerId, productName, name, authorization, updateTreeView  }) {
  const [workflow, setWorkflow] = useState(null);
  const [isWorkflowCloneModalOpen, setIsWorkflowCloneModalOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(statusOptions[0]);
  const { error, loading, retry, retrying } = useAsync(fetchWorkflow, [clientNr, explorerId, productName, name])

  async function fetchWorkflow () {
    const apiUrl = process.env.REACT_APP_CENTRAL_BACK + "/workflow/query";

    const requestBody = {
      clientNr,
      explorerId,
      productName,
      name,
    };

    try {
      const response = await axios.post(apiUrl, encodebody(requestBody), {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = getDecodedBody(response.data);
      setWorkflow(data);
      setSelectedStatus(data.status);
    } catch (error) {
      console.error("Error fetching workflow:", error);
      throw error;
    }
  };

  const handleDescriptionChange = (value) => {
    setWorkflow((prevWorkflow) => ({
      ...prevWorkflow,
      description: value,
    }));
  };

  const handleSequenceChange = (event) => {
    const inputValue = event.target.value;

    // Check if the input is a valid number
    if (/^\d+$/.test(inputValue) || inputValue === '') {
      // If it's a valid number or an empty string, update the state
      setWorkflow((prevWorkflow) => ({
        ...prevWorkflow,
        sequence: inputValue,
      }));
    }
    // If it's not a valid number, you can choose to do nothing or provide feedback to the user
    // For example, show an error message or prevent further action
  };

  const handleClone = () => {
    setIsWorkflowCloneModalOpen(true);
  };

  const handleUpdate = async () => {
    const apiUrl = process.env.REACT_APP_CENTRAL_BACK + '/workflow/update';

    // Define the request body
    const requestBody = {
      clientNr: clientNr,
      explorerId: explorerId,
      productName:productName,
      name:name,
      description: workflow.description,
      sequence: workflow.sequence,
      status: selectedStatus,
    };

    await axios.post(apiUrl, encodebody(requestBody));
    alert("Workflow was succesfully updated.");
  };

  const handleDelete = async () => {
    const apiUrl = process.env.REACT_APP_CENTRAL_BACK + '/workflow/delete';

    // Define the request body
    const requestBody = {
      clientNr: clientNr,
      explorerId: explorerId,
      productName:productName,
      name:name,
    };

      await axios.post(apiUrl, encodebody(requestBody));
      alert("Workflow was succesfully removed.");
      window.location.reload();
      // updateTreeView();
  };

  return (
    error || retrying
    ? (<fetchErrorView
          className="section-right__content"
          retry={retry}
          retrying={retrying}
        />
    ) : (<>
          <header className="section-right__toolbar-container">
            <Toolbar>
              {(authorization.designer || authorization.owner) && (
                <>
                  <Toolbar.Button onClick={handleUpdate}>Update</Toolbar.Button>
                  <Toolbar.Item>
                    <Button color="danger" size="sm" onClick={handleDelete}>Remove</Button>
                  </Toolbar.Item>
                  <Toolbar.Button onClick={handleClone}>Clone</Toolbar.Button>
                </>
              )}
              <Toolbar.Item>
                <Icon href="https://wiki.gwocu.com/en/GWOCU-Studio/workflows-detail-panel">
                  <HelpCenterIcon />
                </Icon>
              </Toolbar.Item>
            </Toolbar>
          </header>
          <div className="section-right__content">
            <Form className="section-right__form">
              <Form.Control>
                <Form.Label htmlFor="workflowName">Workflow Name</Form.Label>
                <InputText
                  type="text"
                  id="workflowName"
                  value={workflow?.name}
                  loading={loading}
                  disabled
                />
              </Form.Control>
              <Form.Control>
                <Form.Label htmlFor="status">Status</Form.Label>
                <Select
                  id="status"
                  value={selectedStatus}
                  onChange={(e) => setSelectedStatus(e.target.value)}
                  disabled= {!authorization.designer && !authorization.owner }
                  options={statusOptions.map((type) => ({label: type, value: type}))}
                  loading={loading}
                />
              </Form.Control>
              <Form.Control>
                <Form.Label htmlFor="sequence">Sequence</Form.Label>
                <InputText
                  id="sequence"
                  value={workflow?.sequence}
                  onChange={handleSequenceChange}
                  disabled = {!authorization.designer && !authorization.owner}
                  loading={loading}
                />
              </Form.Control>
              <Form.Control className="panel__text-editor-section">
                <Form.Label htmlFor="workflowDescription">Description</Form.Label>
                <TextEditor
                  id="workflowDescription"
                  value={workflow?.description}
                  onChange={handleDescriptionChange}
                  readOnly={!authorization.designer && !authorization.owner}
                  loading={loading}
                />
              </Form.Control>
            </Form>
            <Modalworkflowclone
              clientNr={clientNr}
              explorerId={explorerId}
              SourceProductName={productName}
              sourceWorkflowName={name}
              open={isWorkflowCloneModalOpen}
              onClose={() => setIsWorkflowCloneModalOpen(false)}
            />
          </div>
        </>
    ));
}

export default Workflowview;
