import React, { useState } from "react";
import axios from "axios";
import { FetchErrorView } from "../fetchErrorView/FetchErrorView";
import { useTheme } from "../../context/ThemeContext.js";
import { useAsync } from "../../hooks/useAsync";
import { Checkbox, CodeEditor, Form, Icon, InputText, Select, Toolbar } from "../../UI";
import { encodebody, getDecodedBody } from "../../utils/utils.js";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";

const typeOptions = ["STRAIGHT", "CURVE_SMOOTH", "CURVE_FULL"];
const emptyObject = Object.create(null);

function Linkview({ clientNr, explorerId, workflowName, mylink, authorization, updateGraphView }) {
  const [link, setLink] = useState({
    source: mylink.source,
    target: mylink.target,
    type: mylink.type || typeOptions[0],
    passLinkParameters: mylink.passLinkParameters || false,
    sequence: mylink.sequence || "1",
    pathParameters: mylink.pathParameters || emptyObject,
    pathOrder: mylink.pathOrder || [],
    queryParameters: mylink.queryParameters || emptyObject,
    requestbodyParameters: mylink.requestbodyParameters || emptyObject
  });

  const [editors, setEditors] = useState({
    pathParametersEditor: null,
    pathOrderEditor: null,
    queryParametersEditor: null,
    requestBodyParametersEditor: null,
  })

  const [sourceAndTargetNames, setSourceAndTargetNames] = useState({ sourceName: "", targetName: ""});
  const { error, loading, retry, retrying } = useAsync(fetchLink, [workflowName, mylink])
  const { isADarkTheme } = useTheme();
  const readOnly = !authorization.designer && !authorization.owner

  function replaceLink(links) {
    for (let currLink of links) {
      if (currLink.source === link.source && currLink.target === link.target) {
        currLink.type = link.type || typeOptions[0];
        currLink.pathParameters = link.pathParameters;
        currLink.pathOrder = link.pathOrder;
        currLink.queryParameters = link.queryParameters;
        currLink.requestbodyParameters = link.requestbodyParameters;
        currLink.passLinkParameters = link.passLinkParameters || false;
        currLink.sequence = link.sequence || 1;
        // If you want to stop after the first occurrence is replaced, you can return here
        return links;
      }
    }
    return links; // Return the array in case the source/target pair is not found
  }

  function validateParametersAndOrder(parameters, order) {

    // check if both parameters are valid objects:
    if (typeof parameters !== 'object' || parameters === null || Array.isArray(parameters)) {
      return { valid: false, message: "Path Parameters should be an object. It is allowed to be an empty object {}" };
    }

    // Check if order is an array
    if (!Array.isArray(order)) {
      return { valid: false, message: "Path Order should be an array." };
    }

    for (let key of order) {
      if (typeof key !== 'string') {
        return { valid: false, message: "Path Order array should contain only strings." };
      }
    }

    // Check if both parameters and order are empty
    if (Object.keys(parameters).length === 0 && order.length === 0) {
      return { valid: true, message: "Both Path Parameters and Path Order are empty and valid." };
    }

    // Check if only one of the parameters or order is empty
    if ((Object.keys(parameters).length === 0 && order.length > 0) || (Object.keys(parameters).length > 0 && order.length === 0)) {
      return { valid: false, message: "If Path Parameters is empty, Path Order must be empty, and vice versa." };
    }

    // Check if all elements in the order array are keys in the parameters object
    for (let key of order) {
      if (!parameters.hasOwnProperty(key)) {
        return { valid: false, message: `Path Order key '${key}' is not found in Path Parameters.` };
      }
    }

    // Check for duplicate keys in the order array
    let uniqueKeys = new Set(order);
    if (uniqueKeys.size !== order.length) {
      return { valid: false, message: "Path Order contains duplicate keys." };
    }

    // Check if the parameters object has the correct structure

    for (let key in parameters) {
      let value = parameters[key];
      if (typeof key !== 'string' || (typeof value !== 'string' && typeof value !== 'number')) {
        return { valid: false, message: "Path Parameters object should have string keys and string or numeric values." };
      }
      // Check for empty string values
      if (typeof value === 'string' && value.trim() === '') {
        return { valid: false, message: `Path Parameter value for key '${key}' is an empty string.` };
      }
      // Check for reserved characters in values
      if (typeof value === 'string' && /[?&/#]/.test(value)) {
        return { valid: false, message: `Path Parameter value for key '${key}' contains reserved characters.` };
      }
      // Check for valid URL encoding
      try {
        encodeURIComponent(value);
      } catch (e) {
        return { valid: false, message: `Path Parameter value for key '${key}' is not URL-encodable.` };
      }
    }

    return { valid: true, message: "Both Path Parameters and Path Order objects are valid." };
  }

  function validateQueryParameters(queryParameters) {
    // Check if queryParameters is a non-null object

    // Function to check if a value is a valid type (string, number, boolean, or array of these)
    function isValidValue(value) {
      if (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean') {
        return true;
      }
      if (Array.isArray(value) && value.length > 0) {
        return value.every(item => typeof item === 'string' || typeof item === 'number' || typeof item === 'boolean');
      }
      return false;
    }

    if (typeof queryParameters !== 'object' || queryParameters === null || Array.isArray(queryParameters)) {
      return { valid: false, message: "Query parameters is only allowed to be a non array object." };
    }

    for (let key in queryParameters) {
      let value = queryParameters[key];

      // Check if keys are strings
      if (typeof key !== 'string') {
        return { valid: false, message: `Query Parameters key '${key}' is not a string.` };
      }

      // Check if values are of valid types
      if (!isValidValue(value)) {
        return { valid: false, message: `Query Parameters value for key '${key}' is not a valid type.` };
      }

      // Check for reserved characters in keys and values
      if (/[?&/#]/.test(key)) {
        return { valid: false, message: `Query Parameters key '${key}' contains reserved characters.` };
      }

      if (typeof value === 'string' && /[?&/#]/.test(value)) {
        return { valid: false, message: `Query Parameters value for key '${key}' contains reserved characters.` };
      }

      // Check for valid URL encoding
      try {
        encodeURIComponent(key);
        if (typeof value === 'string') {
          encodeURIComponent(value);
        } else if (Array.isArray(value)) {
          value.forEach(item => encodeURIComponent(item));
        }
      } catch (e) {
        return { valid: false, message: `Query parameter key or value for key '${key}' is not URL-encodable.` };
      }
    }

    return { valid: true, message: "Query parameters are valid." };
  }

  const handleUpdate = async () => {
    const pathValidation = validateParametersAndOrder(link.pathParameters, link.pathOrder);
    const queryValidation = validateQueryParameters(link.queryParameters);

    if (!pathValidation.valid) {
      return alert(pathValidation.message);
    }

    if (!queryValidation.valid) {
      return alert(queryValidation.message);
    }

    const MyrequestBody = {
      clientNr: clientNr,
      explorerId: explorerId,
      workflowName:workflowName
    };

    // get original links
    const myQueryResponse = await axios.post(process.env.REACT_APP_CENTRAL_BACK + '/link/query', encodebody(MyrequestBody));
    const myQueryResponseData = getDecodedBody(myQueryResponse.data);
    const myLinks = myQueryResponseData.links;

    // replace the type in the original links
    const myNewLinks = replaceLink(myLinks)

    // Update the links object
    const MyPayload = {
      clientNr: clientNr,
      explorerId: explorerId,
      workflowName: workflowName,
      links: myNewLinks
    };

    await axios.post(process.env.REACT_APP_CENTRAL_BACK + '/link/update', encodebody(MyPayload));
    alert("Link was succesfully updated!")
    updateGraphView();
  };

  async function fetchLink() {
    const myPayloadSource = {
      clientNr: clientNr,
      explorerId: explorerId,
      workflowName: workflowName,
      taskId: mylink.source
    }

    const myPayloadTarget = {
      clientNr: clientNr,
      explorerId: explorerId,
      workflowName: workflowName,
      taskId: mylink.target
    }

    const myResponseSource = await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/task/query", encodebody(myPayloadSource))
    const myResponseTarget = await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/task/query", encodebody(myPayloadTarget))
    const myResponseSourceData = getDecodedBody(myResponseSource.data);
    const myResponseTargetData = getDecodedBody(myResponseTarget.data);

    const mySourceAndTargetNames = {
      sourceName: myResponseSourceData.name,
      targetName: myResponseTargetData.name,
    }

    setSourceAndTargetNames(mySourceAndTargetNames);

    const pathParameters = mylink.pathParameters || emptyObject;
    const pathOrder = mylink.pathOrder || [];
    const queryParameters = mylink.queryParameters || emptyObject;
    const requestbodyParameters = mylink.requestbodyParameters || emptyObject;

    setLink({
      source: mylink.source,
      target: mylink.target,
      type: mylink.type || typeOptions[0],
      passLinkParameters: mylink.passLinkParameters || false,
      sequence: mylink.sequence || "1",
      pathParameters,
      pathOrder,
      queryParameters,
      requestbodyParameters
    });

    editors.pathParametersEditor?.setValue(JSON.stringify(pathParameters, null, 2));
    editors.pathOrderEditor?.setValue(JSON.stringify(pathOrder, null, 2));
    editors.queryParametersEditor?.setValue(JSON.stringify(queryParameters, null, 2));
    editors.requestBodyParametersEditor?.setValue(JSON.stringify(requestbodyParameters, null, 2));
  }

  const handlePassParametersChange = (event) => setLink(prevLink => ({...prevLink, passLinkParameters: event.target.checked}))
  const handleTypeChange = (event) => setLink(prevLink => ({...prevLink, type: event.target.value}))

  const handleSequenceChange = (event) => {
    const sequence = event.target.value;

    // Check if the input is a valid number
    if (/^\d+$/.test(sequence) || sequence === '') {
      // If it's a valid number or an empty string, update the state
      // setSelectedSequence(inputValue)
      setLink(prevLink => ({...prevLink, sequence}))
    }
    // If it's not a valid number, you can choose to do nothing or provide feedback to the user
    // For example, show an error message or prevent further action
  };

  const handleJsonChange = (value, fieldName, defaultValue = {}) => {
    let parsedValue;
    // Check if the value is an empty string and set it to an empty object
    if (value === '') {
      parsedValue = defaultValue;
    } else {
      try {
        // Attempt to parse the value as JSON
        parsedValue = JSON.parse(value);
      } catch (error) {
        console.error("Invalid JSON:", error);
        // If the value is not valid JSON, you might want to handle this case,
        // e.g., by not calling setApiData, showing an error message, etc.
        return; // Exit the function if the JSON is invalid
      }
    }

    // Update the state  with the parsed value, which is now guaranteed to be an object
    setLink(prevLink => ({...prevLink, [fieldName]: parsedValue}));
  };

  const handlePathParametersChange = (value) => handleJsonChange(value, "pathParameters")
  const handlePathOrderChange = (value) => handleJsonChange(value, "pathOrder", [])
  const handleQueryParametersChange = (value) => handleJsonChange(value, "queryParameters")
  const handleRequestBodyParametersChange = (value) => handleJsonChange(value, "requestbodyParameters")

  const setEditorOnMount = (editorId) => (editor) => setEditors(prevEditors => ({...prevEditors, [editorId]: editor}))

  return (
    error || retrying
    ? (<FetchErrorView
        className="section-right__content"
        retry={retry}
        retrying={retrying}
      />
    ) : (
        <>
          {(authorization.designer || authorization.owner) && (
            <header className="section-right__toolbar-container">
              <Toolbar>
              <Toolbar.Button onClick={handleUpdate}>Update</Toolbar.Button>
              <Toolbar.Item>
                <Icon href="https://wiki.gwocu.com/en/GWOCU-Studio/link-datail-panel">
                  <HelpCenterIcon />
                </Icon>
              </Toolbar.Item>
              </Toolbar>
            </header>
          )}
          <div className="section-right__content">
            <Form className="section-right__form">
              <Form.Control>
                <Form.Label htmlFor="source">Source</Form.Label>
                <InputText
                  id="source"
                  value={sourceAndTargetNames?.sourceName}
                  loading={loading}
                  disabled
                />
              </Form.Control>
              <Form.Control>
                <Form.Label htmlFor="target">Target</Form.Label>
                <InputText
                  id="target"
                  value={sourceAndTargetNames?.targetName}
                  loading={loading}
                  disabled
                />
              </Form.Control>
              <Form.Control>
                <Form.Label htmlFor="sequence">Sequence</Form.Label>
                <InputText
                  id="sequence"
                  value={link?.sequence}
                  onChange={handleSequenceChange}
                  disabled={readOnly}
                  loading={loading}
                />
              </Form.Control>
              <Form.Control>
                <Form.Label htmlFor="linkType">Link Type</Form.Label>
                <Select
                  id="linkType"
                  value={link?.type}
                  onChange={handleTypeChange}
                  options={typeOptions.map((type) => ({label: type, value: type}))}
                  loading={loading}
                  disabled={!authorization.designer && !authorization.owner}
                />
              </Form.Control>
              <Form.Col>
                <Checkbox
                  checked={link?.passLinkParameters}
                  disabled={readOnly}
                  id="passLinkParameters"
                  onChange={handlePassParametersChange}
                  loading={loading}
                >
                  Pass following Parameters to target API..
                </Checkbox>
              </Form.Col>
              <Form.Control>
                <Form.Label htmlFor="pathParameter">Resource and Path Parameters</Form.Label>
                <CodeEditor
                  id='json-editor1'
                  defaultLanguage="json"
                  defaultValue={link?.pathParameters}
                  onChange={handlePathParametersChange}
                  onMount={setEditorOnMount("pathParametersEditor")}
                  darkMode={isADarkTheme}
                  readOnly={readOnly}
                  loading={loading}
                />
              </Form.Control>
              <Form.Control>
                <Form.Label htmlFor="pathOrder">Path Order</Form.Label>
                <CodeEditor
                  id='json-editor1-1'
                  defaultLanguage="json"
                  defaultValue={link?.pathOrder}
                  onChange={handlePathOrderChange}
                  darkMode={isADarkTheme}
                  readOnly={readOnly}
                  onMount={setEditorOnMount("pathOrderEditor")}
                  loading={loading}
                  />
              </Form.Control>
              <Form.Control>
                <Form.Label htmlFor="queryParameter">Query Parameters</Form.Label>
                <CodeEditor
                  id='json-editor2'
                  defaultLanguage="json"
                  defaultValue= {link?.queryParameters}
                  onChange={handleQueryParametersChange}
                  darkMode={isADarkTheme}
                  readOnly={readOnly}
                  onMount={setEditorOnMount("queryParametersEditor")}
                  loading={loading}
                  />
              </Form.Control>
              <Form.Control>
                <Form.Label htmlFor="requestbodyParameter">Requestbody Parameters</Form.Label>
                <CodeEditor
                  id='json-editor3'
                  defaultLanguage="json"
                  defaultValue= {link?.requestbodyParameters}
                  onChange={handleRequestBodyParametersChange}
                  darkMode={isADarkTheme}
                  readOnly={readOnly}
                  onMount={setEditorOnMount("requestBodyParametersEditor")}
                  loading={loading}
                  />
              </Form.Control>
            </Form>
          </div>
        </>
      )
  );
}

export default Linkview;
