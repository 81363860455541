import { Replay } from "@mui/icons-material";
import { Button, Icon, Spinner } from "../../UI";
import "./FetchErrorView.css";

export const FetchErrorView = ({
  className,
  errorMessage = "Error loading information",
  retry,
  retrying,
}) => {
  const classes = `fetch-error ${className || ""}`;
  return (
    <div className={classes}>
      <p className="fetch-error__message">{errorMessage}</p>
      <Button
        className="fetch-error__button"
        onClick={retry}
        disabled={retrying}
      >
        {retrying ? (
          <>
            <span>Loading...</span>
            <Spinner thickness={5} />
          </>
        ) : (
          <>
            <span>Try again</span>
            <Icon size="xs" color="primary">
              <Replay />
            </Icon>
          </>
        )}
      </Button>
    </div>
  );
};
