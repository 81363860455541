import { forwardRef, useEffect, useState } from "react";
import styled from "styled-components";
import { blink } from "./animations";
import Skeleton from "./Skeleton";

const StyledSelect = styled.select`
  font-family: inherit;
  font-size: inherit;
  background-color: var(--input-bg-clr);
  border: 2px solid var(--input-border-clr);
  color: ${({ $chosePlaceholder }) =>
    $chosePlaceholder
      ? "var(--input-placeholder-clr)"
      : "var(--input-txt-clr)"};
  width: 100%;
  padding: 0.8rem 1.2rem;
  border-radius: 0.5em;
  transition:
    border-color var(--transition-duration-normal, 250ms) ease,
    outline var(--transition-duration-normal, 250ms) ease;

  &:hover,
  &:active,
  &:focus-visible,
  &:focus-within {
    background-color: var(--input-bg-clr-hover);
    border-color: var(--input-border-clr-hover);
    color: ${({ $chosePlaceholder }) =>
      $chosePlaceholder
        ? "var(--input-placeholder-clr)"
        : "var(--input-txt-clr-hover)"};
  }

  &:focus-visible,
  &:focus-within {
    outline: 2px solid var(--input-border-clr-hover);
    animation: ${blink} 2s infinite;
  }

  &:disabled {
    color: var(--input-txt-clr-disabled);
    background-color: var(--input-bg-clr-disabled);
    border: 2px solid var(--input-border-clr-disabled);
    cursor: not-allowed;
  }

  & option {
    font-size: 1.1em;
  }

  &.invalid {
    background-color: var(--input-bg-clr-invalid);
    color: ${({ $chosePlaceholder }) =>
      $chosePlaceholder
        ? "var(--input-placeholder-clr-invalid)"
        : "var(--input-txt-clr-invalid)"};
    border-color: var(--input-border-clr-invalid);
  }

  &.invalid:focus-visible,
  &.invalid:focus-within {
    outline-color: var(--input-border-clr-invalid);
  }
`;

/**
 * @typedef {Object} OptionProps
 * @property {boolean} [disabled=false] - If `true`, the `option` cannot be selected. Default value: `false`.
 * @property {string} label - The text that indicates the meaning of the option.
 * @property {boolean} [selected=false] - If `true`, the `option` will be initially selected. Default value: `false`.
 * @property {string} value - The value to be submitted with the form.
 */

/**
 * @typedef {Object} SelectProps
 * @property {string} [className=undefined] - Adds custom `className` for the component. Default value: `undefined`.
 * @property {boolean} [defaultValue=undefined] - Sets the initially selected option for the component. Default value: `undefined`.
 * @property {boolean} [disabled=false] - If `true`, the select component is disabled. Default value: `false`.
 * @property {boolean} [error=false] - If `true`, the styling changes to indicate the selected option is invalid. Default value: `false`.
 * @property {string} [id=undefined] - The id of the select component. Default value: `undefined`.
 * @property {boolean} [multiple=false] - If `true`, multiple selections are allowed. Default value: `false`.
 * @property {function} [onChange=undefined] - The `change` event handler. Default value: `undefined`.
 * @property {{label: string, value: string, disabled: boolean, selected: boolean}[]} [props.options] - The array of options for the select component. Default value: `[]`.
 * @property {string} [placeholder=undefined] - The placeholder text. Default value: `undefined`.
 * @property {boolean} [required=false] - If `true`, the select component is required. Default value: `false`.
 * @property {string} [value=undefined] - The value of the `select` element. Default value: `undefined`.
 */

/**
 * GWOCU's select component
 *
 * It is a customized native select element. It supports single and multiple selections, event handling as well as error and disabled states.
 * @component
 * @param {SelectProps} props - The props for the select component.
 * @param {React.Ref} ref - The ref passed to the select element.
 * @returns {JSX.Element} The rendered `Select` component.
 *
 * @example
 * <Select
 *   placeholder="Select an option"
 *   value={value}
 *   onChange={changeHandler}
 *   options={[
 *     {
 *       label: "This is what users read",
 *       value: "value-for-the-backend"
 *     }
 *   ]}
 * />
 */
export const Select = forwardRef(
  (
    /** @type {SelectProps} */ {
      className,
      defaultValue,
      disabled = false,
      error = false,
      id,
      loading = false,
      multiple = false,
      onChange,
      options = [],
      placeholder,
      required = false,
      value,
    },
    /** @type {React.RefAttributes<HTMLInputElement>} */ ref
  ) => {
    const [placeholderVisible, setPlaceholderVisible] = useState(!!placeholder);
    const classes = `${className || ""} ${error ? "invalid" : ""}`;
    const selectState = {};

    // Handle controlled/uncontrolled select
    if (value || value === "") {
      selectState.value = value;
    } else if (defaultValue || defaultValue === "") {
      selectState.defaultValue = defaultValue;
    } else {
      selectState.defaultValue = placeholder ? "" : options?.[0]?.value;
    }

    useEffect(() => {
      if (!placeholder) return;
      setPlaceholderVisible(value === "" || ref?.current?.value === "");
    }, [value, ref, placeholder]);

    return loading ? (
      <Skeleton height="3.76rem" className={className} />
    ) : (
      <StyledSelect
        ref={ref}
        id={id}
        onChange={onChange}
        className={classes}
        multiple={multiple}
        disabled={disabled}
        required={required}
        $chosePlaceholder={placeholderVisible}
        {...selectState}
      >
        {placeholder && (
          <option value="" disabled>
            {placeholder}
          </option>
        )}
        {options?.map((option) => (
          <option
            value={option.value}
            key={option.value}
            disabled={option?.disabled}
          >
            {option.label}
          </option>
        ))}
      </StyledSelect>
    );
  }
);
