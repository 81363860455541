import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Checkbox, Form, Icon, InputText, Modal, ProgressBar } from '../../UI';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import { generateMarkdown } from '../../utils/markdownUtils';
import {makeCurlMarkdownFromApi } from "../../utils/api-spec-util.js";
import { getDecodedBody, encodebody } from "../../utils/utils.js";

const ExportGuide = ({ clientNr, explorerId, open, onClose }) => {
  const [fileName, setFileName] = useState('export');
  const [version, setVersion] = useState('1.0');
  const [products, setProducts] = useState([]);
  const [workflows, setWorkflows] = useState({});
  const [selectedProducts, setSelectedProducts] = useState({});
  const [selectedWorkflows, setSelectedWorkflows] = useState({});
  const [selectedTasks, setSelectedTasks] = useState({});
  const [exportAllProducts, setExportAllProducts] = useState(false);
  const [collapsed, setCollapsed] = useState({}); // State to manage expand/collapse
  const [progress, setProgress] = useState(0);
  const [loadingWorkflows, setLoadingWorkflows] = useState({});
  const [loadingTasks, setLoadingTasks] = useState({});


  useEffect(() => {
    axios.post(process.env.REACT_APP_CENTRAL_BACK + '/product/queryall', { clientNr, explorerId })
      .then(response => {
        const sortedProducts = response.data.sort((a, b) => a.sequence - b.sequence);
        setProducts(sortedProducts);
      })
      .catch(error => console.error("Error fetching products:", error));
  }, [clientNr, explorerId]);

  const fetchWorkflowsForProduct = async (product) => {
    setLoadingWorkflows(prev => ({ ...prev, [product.productName]: true }));
    try {
      const workflowResponse = await axios.post(process.env.REACT_APP_CENTRAL_BACK + '/workflow/queryallgivenproduct', {
        clientNr: product.clientNr,
        explorerId: product.explorerId,
        productName: product.productName,
      });
      const sortedWorkflows = workflowResponse.data.sort((a, b) => a.sequence - b.sequence);
      const workflowsWithTasks = await Promise.all(sortedWorkflows.map(async (workflow) => {
        const tasks = await fetchOrderedTasks(workflow);
        return { ...workflow, tasks };
      }));
      setWorkflows(prevWorkflows => ({
        ...prevWorkflows,
        [product.productName]: workflowsWithTasks,
      }));
    } catch (error) {
      console.error(`Error fetching workflows for product ${product.productName}:`, error);
    }
    finally {
      setLoadingWorkflows(prev => ({ ...prev, [product.productName]: false }));
    }
  };

  const fetchOrderedTasks = async (workflow) => {
    setLoadingTasks(prev => ({ ...prev, [workflow.name]: true }));
    try {
      const taskResponse = await axios.post(process.env.REACT_APP_CENTRAL_BACK + '/link/queryorderedtasks', { 
        clientNr: workflow.clientNr, 
        explorerId: workflow.explorerId, 
        workflowName: workflow.name 
      });
      return taskResponse.data;
    } catch (error) {
      console.error(`Error fetching tasks for workflow ${workflow.name}:`, error);
      return [];
    }
    finally {
      setLoadingTasks(prev => ({ ...prev, [workflow.name]: false }));
    }
  };

  const fetchApi = async (apiName) => {
    try {
      const response = await axios.post(process.env.REACT_APP_CENTRAL_BACK + '/api/query', { clientNr, explorerId, name: apiName });
      return response.data;
    } catch (error) {
      console.error(`Error fetching API ${apiName}:`, error);
      return null
    }
  }

  const fetchExplorer = async () => {
    try {
      const myExplorerbody = {
        clientNr: clientNr,
        explorerId: explorerId
      };
      const response = await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/explorer/query", myExplorerbody);
      return response.data;
    } catch (error) {
      console.error(`Error fetching Explorer:`, error);
      return null
    }
  }

  const toggleCollapse = (id) => {
    setCollapsed(prevState => ({ ...prevState, [id]: !prevState[id] }));
  };

  const handleProductChange = (productId) => {
    setSelectedProducts(prevState => ({
      ...prevState,
      [productId]: !prevState[productId]
    }));
  };

  const updateProgress = (increment) => {
    setProgress((prevProgress) => prevProgress + increment);
  };

  const handleWorkflowChange = (workflowId, productId, tasks) => {
    setSelectedWorkflows(prevState => {
      const newSelection = { ...prevState, [workflowId]: !prevState[workflowId] };
      
      // When selecting/unselecting a workflow, set all its tasks to match its new state
      const newSelectedTasks = { ...selectedTasks };
      tasks.forEach(task => {
        newSelectedTasks[task._id] = newSelection[workflowId];
      });
      setSelectedTasks(newSelectedTasks);

      return newSelection;
    });
  };

  const handleTaskChange = (taskId) => {
    setSelectedTasks(prevState => ({
      ...prevState,
      [taskId]: !prevState[taskId]
    }));
  };

  
  const handleExport = async () => {
    const totalSteps = 5 ;
    const progressIncrement = 100 / 4;
    updateProgress(progressIncrement);  //step 1

    let markdownGuide = '';
    markdownGuide += generateMarkdown("Products", 'H1') + '\n';

    const explorer = await fetchExplorer();

    // Determine the products to export
    const filteredProducts = exportAllProducts
        ? products
        : products.filter(product => selectedProducts[product._id]);

    for (const product of filteredProducts) {
        // Fetch workflows for the current product
        await fetchWorkflowsForProduct(product);

        markdownGuide += generateMarkdown(product.productName, 'H1') + '\n';
        markdownGuide += generateMarkdown(product.description, null) + '\n\n';

        const productWorkflows = workflows[product.productName] || [];
        markdownGuide += generateMarkdown("Workflows belonging to " + product.productName, 'H1') + '\n';

        for (const workflow of productWorkflows) {
            // Check workflow selection if not exporting all
            if (exportAllProducts || selectedWorkflows[workflow._id]) {
                markdownGuide += generateMarkdown("Workflow: " + workflow.name, 'H2') + '\n';
                markdownGuide += generateMarkdown(workflow.description, null) + '\n\n';

                const tasks = workflow.tasks || await fetchOrderedTasks(workflow);
                markdownGuide += generateMarkdown("Tasks belonging to " + workflow.name, 'H3') + '\n';

                for (const task of tasks) {
                    // Check task selection if not exporting all
                    if (exportAllProducts || selectedTasks[task._id]) {
                        markdownGuide += generateMarkdown("Task: " + task.name, 'H3') + '\n';
                        markdownGuide += generateMarkdown(task.description, null) + '\n\n';

                        if (task.apiName !== '') {
                            const api = await fetchApi(task.apiName);

                            if (api) {
                                markdownGuide += generateMarkdown("API belonging to " + task.name, 'H4') + '\n';

                                // Include API name and description
                                markdownGuide += generateMarkdown("API: " + api.name, 'H5') + '\n';
                                markdownGuide += generateMarkdown(api.description, null) + '\n';

                                // Include API curl command
                                const myCurl = await makeCurlMarkdownFromApi(explorer, workflow.name, task.taskId, api);
                                markdownGuide += myCurl + '\n';

                                // Include Request Body
                                if (api.requestBody && Object.keys(api.requestBody).length > 0) {
                                    markdownGuide += generateMarkdown("Request Body:", 'H5') + '\n';
                                    markdownGuide += generateMarkdown(JSON.stringify(api.requestBody, null, 2), null, true) + '\n';
                                }

                                // Include Parameters Description
                                if (api.parametersDescription && Object.keys(api.parametersDescription).length > 0) {
                                    markdownGuide += generateMarkdown("Parameters Description:", 'H5') + '\n';
                                    markdownGuide += generateMarkdown(JSON.stringify(api.parametersDescription, null, 2), null, true) + '\n';
                                }

                                // Include Response Body
                                if (api.responseBody && Object.keys(api.responseBody).length > 0) {
                                    markdownGuide += generateMarkdown("Response Body Example:", 'H5') + '\n';
                                    markdownGuide += generateMarkdown(JSON.stringify(api.responseBody, null, 2), null, true) + '\n';
                                }

                                // Include Additional Information
                                if (api.thirdparty && api.thirdparty !== "none") {
                                    markdownGuide += generateMarkdown(`API Action: ${api.thirdparty}`, null) + '\n';
                                }

                                markdownGuide += generateMarkdown('', null, false, true); // Page break
                            }
                        }
                    }
                }
            }
        }
    }

    updateProgress(progressIncrement);  //step 2

    // Generate the Markdown file
    const fullFileName = `${fileName}_v${version}.md`;
    const blob = new Blob([markdownGuide], { type: 'text/markdown' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fullFileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    updateProgress(progressIncrement);  //step 3
    setProgress(100);
      setTimeout(() => {
        setProgress(0);
      }, 500);
};


  return (
    <Modal open={open} onClose={onClose} maxWidth="48rem">
      <Modal.Title>
        Export Guides
        <Icon size="sm" href="https://wiki.gwocu.com/en/GWOCU-Studio/product-tree-panel-menu#exportproducts-section">
          <HelpCenterIcon />
        </Icon>
      </Modal.Title>
      <Modal.Body>
        <Form onSubmit={handleExport}>
          <Form.Control>
            <Form.Label htmlFor="fileName">File Name</Form.Label>
            <InputText
              id="fileName"
              value={fileName}
              onChange={e => setFileName(e.target.value)}
              placeholder="File Name"
            />
          </Form.Control>
          <Form.Control>
            <Form.Label htmlFor="version">Version</Form.Label>
            <InputText
              id="version"
              value={version}
              onChange={e => setVersion(e.target.value)}
              placeholder="Version"
            />
          </Form.Control>
  
          <div style={{ marginBottom: '10px' }}> {/* Wrapper div for spacing */}
          <Checkbox
            id="exportAllProducts"
            checked={exportAllProducts}
            onChange={e => setExportAllProducts(e.target.checked)}
          >
            Export all products to guides
          </Checkbox>
          </div>
          <hr style={{ border: '1px solid #ddd', marginBottom: '20px' }} /> {/* Divider line */}
  
          <div>
            {products.map((product) => (
              <div key={product._id} style={{ marginBottom: '10px' }}>
                {/* Container for Product Checkbox and Name */}
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox
                    checked={!!selectedProducts[product._id]}
                    onChange={() => handleProductChange(product._id)}
                    disabled={exportAllProducts}
                  />
                  <span 
                    onClick={() => {
                      toggleCollapse(product._id);
                      if (!workflows[product.productName]) {
                        fetchWorkflowsForProduct(product); // Fetch workflows when expanding a product
                      }
                    }}
                    style={{ cursor: 'pointer', marginLeft: '10px' }}
                  >
                    {product.productName}
                  </span>
                  {loadingWorkflows[product.productName] && (
                  <span style={{ marginLeft: '10px' }}>Loading workflows...</span>
                  )}
                </div>
  
                {/* Collapsible Workflows for Product */}
                {collapsed[product._id] && (
                  <div style={{ marginLeft: '20px' }}>
                    {(workflows[product.productName] || []).map((workflow) => (
                      <div key={workflow._id} style={{ marginBottom: '10px' }}>
                        {/* Container for Workflow Checkbox and Name */}
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Checkbox
                            checked={!!selectedWorkflows[workflow._id]}
                            onChange={() => handleWorkflowChange(workflow._id, product._id, workflow.tasks)}
                            disabled={!selectedProducts[product._id] || exportAllProducts}
                          />
                          <span 
                            onClick={() => toggleCollapse(workflow._id)}
                            style={{ cursor: 'pointer', marginLeft: '10px' }}
                          >
                            {workflow.name}
                          </span>
                          {loadingTasks[workflow.name] && (
                           <span style={{ marginLeft: '10px' }}>Loading tasks...</span>
                           )}
                        </div>
  
                        {/* Collapsible Tasks for Workflow */}
                        {collapsed[workflow._id] && (
                          <div style={{ marginLeft: '20px' }}>
                            {workflow.tasks.map((task) => (
                              <div key={task._id} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                <Checkbox
                                  checked={!!selectedTasks[task._id]}
                                  onChange={() => handleTaskChange(task._id)}
                                  disabled={!selectedWorkflows[workflow._id] || exportAllProducts}
                                />
                                <span style={{ marginLeft: '10px' }}>{task.name}</span>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
          {progress > 0 && (
            <Form.Row>
              <ProgressBar progress={progress} />
            </Form.Row>
          )}
          <Form.Row>
            <Button color="secondary" onClick={onClose}>Cancel</Button>
            <Button type="submit">Export Guide</Button>
          </Form.Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
  
};

export default ExportGuide;