import TurndownService from 'turndown';

// Initialize the Turndown service for HTML to Markdown conversion
const turndownService = new TurndownService();

/**
 * Converts text to markdown with optional header, code block, and page break.
 * @param {string} text - The input text in HTML, plain text, or markdown format.
 * @param {string} [header] - The header level, can be "H1", "H2", or "H3". Optional.
 * @param {boolean} isCodeBlock - Whether to wrap the text in a markdown code block.
 * @param {boolean} pageBreak - Whether to add a page break (---) after the text.
 * @returns {string} The formatted markdown text with the specified header, code block, and page break.
 */
export function generateMarkdown(text, header = null, isCodeBlock = false, pageBreak = false) {
  let markdownText = text;

  // Detect the format of `text`
  const isHTML = /<\/?[a-z][\s\S]*>/i.test(text); // Check for HTML tags
  const isMarkdown = /[#*_\-`]/.test(text); // Basic check for markdown symbols

  // Convert to markdown if it's HTML or plain text
  if (isHTML) {
    markdownText = turndownService.turndown(text); // Convert HTML to markdown
  } else if (!isMarkdown) {
    markdownText = text; // Treat as plain text, no conversion needed
  }

  // Add the appropriate markdown header if provided
  let markdownHeader = '';
  if (header) {
    switch (header) {
      case 'H1':
        markdownHeader = `# `;
        break;
      case 'H2':
        markdownHeader = `## `;
        break;
      case 'H3':
        markdownHeader = `### `;
        break;
      case 'H4':
        markdownHeader = `#### `;
        break;
      case 'H5':
        markdownHeader = `##### `;
        break;
      default:
        markdownHeader = `##### `;;
    }
  }

  // Wrap text in a markdown code block if `isCodeBlock` is true
  if (isCodeBlock) {
    markdownText = `\`\`\`\n${markdownText}\n\`\`\``;
  }

  // Combine the header (if any) and content
  let finalText = `${markdownHeader}${markdownText}`;

  // Add a page break after the content if `pageBreak` is true
  if (pageBreak) {
    finalText += `\n\n---\n\n`;
  }

  return finalText;
}

// Example usage
// console.log(generateMarkdown('<h1>Hello World</h1>', 'H2', true, true));
// console.log(generateMarkdown('Plain text example', 'H1', false, true));
// console.log(generateMarkdown('# Markdown example', 'H3', false, false));
// console.log(generateMarkdown('No header or code block', null, false, true));
