import { useCallback, useEffect, useState } from "react";

const FETCH_STATUS = {
  INIT: 0,
  LOADING: 1,
  RETRYING: 2,
  ERROR: 3,
  SUCCESS: 4,
};

export const useAsync = (callback, dependencies = []) => {
  const [status, setStatus] = useState(FETCH_STATUS.INIT);
  const [value, setValue] = useState(null)

  const callbackMemoized = useCallback(() => {
    setStatus((prevStatus) => {
      const hadFailed =
        prevStatus === FETCH_STATUS.ERROR ||
        prevStatus === FETCH_STATUS.RETRYING;

      return hadFailed ? FETCH_STATUS.RETRYING : FETCH_STATUS.LOADING;
    });

    callback()
      .then((returnedValue) => {
        setValue(returnedValue)
        setStatus(FETCH_STATUS.SUCCESS)
      })
      .catch(() => setStatus(FETCH_STATUS.ERROR));
  }, dependencies);

  useEffect(() => {
    callbackMemoized();
  }, dependencies);

  return {
    loading: status === FETCH_STATUS.LOADING,
    error: status === FETCH_STATUS.ERROR,
    success: status === FETCH_STATUS.SUCCESS,
    retrying: status === FETCH_STATUS.RETRYING,
    retry: callbackMemoized,
    value
  };
};
