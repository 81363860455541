import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactJson from 'react-json-view';
import { useTheme } from '../../context/ThemeContext.js';
import { Button, CodeEditor, Form, InputText, Select, Terminal, TextEditor } from "../../UI";
import { HeadersGlobalAdd, requestBodyGlobalAdd, addAuthToHeaders, addAuthToRequestBody, parseApiHeaders, getConfiguration, isValidConfiguration, isObject } from "../../utils/api-spec-util.js";
import { encodebody, getDecodedBody } from "../../utils/utils.js";
import { Tab, Tabs } from '@mui/material';
import { AddBox, Delete } from '@mui/icons-material';
import './apidetails.css'; // Import your CSS file here

function APIDetails({ clientNr, explorerId, api, apiName}) {
  const [value, setValue] = useState(0);
  const [apiData, setApiData] = useState({...api});
  const [headers, setHeaders] = useState([]);
  const [explorer, setExplorer] = useState([]);
  const [selectedThirdParty, setSelectedThirdParty] = useState("none");
  const [thirdparties, setThirdparties] = useState([]);
  const { isADarkTheme } = useTheme();

  const [jsonErrors, setJsonErrors] = useState({
    reqBody: [],
    responseBody: [],
    paramDesc: []
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleApiDataChange = (field, value) => {
    setApiData(prevApiData => ({ ...prevApiData, [field]: value }));
  };

  // const handleApiDataChange = (field, value) => {
  //   setApiData({ ...apiData, [field]: value });
  // };

  const validateJson = (inputName) => (errors) => {
    const formattedErrors = errors.map(({message, endLineNumber, endColumn}) => `${message} in line: ${endLineNumber}, column: ${endColumn}`)
    setJsonErrors(prevErrors => ({...prevErrors, [inputName]: formattedErrors}))
  }

  const clearJsonErrors = (inputName) => () => {
    setJsonErrors(prevErrors => ({...prevErrors, [inputName]: []}))
  }

  const handleRequestBodyChange = (value, event) => {
    let parsedValue;
    // Check if the value is an empty string and set it to an empty object
    if (value === '') {
      parsedValue = {};
    } else {
      try {
        // Attempt to parse the value as JSON
        parsedValue = JSON.parse(value);
      } catch (error) {
        console.error("Invalid JSON:", error);
        // If the value is not valid JSON, you might want to handle this case,
        // e.g., by not calling setApiData, showing an error message, etc.
        return; // Exit the function if the JSON is invalid
      }
    }

    // Update the state with the parsed value, which is now guaranteed to be an object
    setApiData({ ...apiData, ["requestBody"]: parsedValue });
  };


  const handleResponseBodyChange = (value, event) => {
    let parsedValue;
    // Check if the value is an empty string and set it to an empty object
    if (value === '') {
      parsedValue = {};
    } else {
      try {
        // Attempt to parse the value as JSON
        parsedValue = JSON.parse(value);
      } catch (error) {
        console.error("Invalid JSON:", error);
        // If the value is not valid JSON, you might want to handle this case,
        // e.g., by not calling setApiData, showing an error message, etc.
        return; // Exit the function if the JSON is invalid
      }
    }

    // Update the state with the parsed value, which is now guaranteed to be an object
    setApiData({ ...apiData, ["responseBody"]: parsedValue });
  };

  const handleDescriptionChange = (value) => {
    setApiData({ ...apiData, ['description']: value });
  };

  const handleParametersDescriptionChange = (value, event) => {
    let parsedValue;
    // Check if the value is an empty string and set it to an empty object
    if (value === '') {
      parsedValue = {};
    } else {
      try {
        // Attempt to parse the value as JSON
        parsedValue = JSON.parse(value);
      } catch (error) {
        console.error("Invalid JSON:", error);
        // If the value is not valid JSON, you might want to handle this case,
        // e.g., by not calling setApiData, showing an error message, etc.
        return; // Exit the function if the JSON is invalid
      }
    }
    // Update the state with the parsed value, which is now guaranteed to be an object
    setApiData({ ...apiData, ["parametersDescription"]: parsedValue });
  };

  const handleHeaderChange = (index, field, value) => {
    const newHeaders = [...headers];
    newHeaders[index][field] = value;
    setHeaders(newHeaders);
  };

  const addHeader = () => {
    setHeaders([...headers, { headerName: '', value: '' }]);
  };

  const deleteHeader = (index) => {
    const newHeaders = headers.filter((_, i) => i !== index);
    setHeaders(newHeaders);
  };

  const updateApi = async () => {
    // Convert headers back to the string format expected by the backend
    const headersAsStringArray = headers.map(({ headerName, value }) => `${headerName}: ${value}`);

    let errorMessage = ""
    const {reqBody: reqBodyErrors, paramDesc: paramDescErrors} = jsonErrors;

    if(reqBodyErrors.length > 0) {
      errorMessage += `Invalid JSON in Request Body:\n${reqBodyErrors.join("\n")}`
    }

    if(paramDescErrors.length > 0) {
      errorMessage += errorMessage.length ? "\n\n" : ""
      errorMessage += `Invalid JSON in Parameters Description:\n${paramDescErrors.join("\n")}`
    }

    if(errorMessage) {
      return alert(errorMessage);
    }

    const updatedApi = { ...apiData, headers: headersAsStringArray };

    // Update API logic using Axios
    try {
      const response = await axios.post(process.env.REACT_APP_CENTRAL_BACK + '/api/update', encodebody(updatedApi)); //  to your actual API endpoint
      alert("Api was succesfully updated!")
    }
    catch (error) {
      if (error.response) {
        alert(`Error updating API: ${getDecodedBody(error.response.data.message) || 'An error occurred'}`);
      } else if (error.request) {
        alert('Error updating API: No response received from the server.');
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error('Error message:', error.message);
        alert(`Error updating API: ${error.message}`);
      }
  }
};

useEffect(() => {
  const fetchExplorer = async () => {
    const myExplorerbody = { clientNr, explorerId };
    try {
      const Eresponse = await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/explorer/query", encodebody(myExplorerbody));
      const myExplorer = getDecodedBody(Eresponse.data);
      setExplorer(myExplorer);
    } catch (error) {
      console.log('Error while fetching explorer:', error);
    }
  };

  const fetchThirdParties = async () => {
    const myBody = { clientNr:clientNr, explorerId:explorerId };
    try {
      const thirdpartiesresponse = await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/thirdparties/queryall", encodebody(myBody));
      const myEmptyThirdParty = { name: "none" };
      const mythirdparties = getDecodedBody(thirdpartiesresponse.data);
      mythirdparties.unshift(myEmptyThirdParty);
      setThirdparties(mythirdparties);
    } catch (error) {
      console.error("Error fetching third party data:", error);
    }
  };

  if (api) {
    fetchExplorer();
    fetchThirdParties();
    const newApiData = { ...api };
    
    setApiData(newApiData);

    const newHeaders = Array.isArray(api.headers) ? api.headers.map(header => {
      const [headerName, value] = header.split(': ');
      return { headerName, value };
    }) : [];
    setHeaders(newHeaders);

    setValue(0); // Reset the tab to the first one
  }
}, [apiName, clientNr, explorerId]);


useEffect(() => {
 
}, [apiData]);


  const commands = {
    whoami: () => {
      
      return "jackharper"
    },
    cd: (directory) => {
      const myreturn = `changed path to ${directory}`;
      return myreturn;
    },
    run: async () => {
      try {
        // Execute the handleSubmit logic when "run" is entered in the terminal
        const myResponse = await handleSubmit();
      
        let jsonResponse;
        if (typeof myResponse === 'string') {
          // Convert string to a valid JSON object
          
          jsonResponse = { data: myResponse };
        } else {
          jsonResponse =  myResponse;
        }
        return (
          <ReactJson src={jsonResponse} theme="apathy" name={null} collapsed={1} />
        );
      } catch (error) {
        console.error('Error during API execution:', error);
        return <div className="terminal__message terminal__message--error">An error occurred during API execution</div>;
      }
    },
  };

  function removeProperty(propertyName, object) {
    // create a new object to store the result
    let result = {};
    // loop through the keys of the original object
    for (let key in object) {
      // if the key is not equal to the property name to remove
      if (key !== propertyName) {
        // copy the key-value pair to the result object
        result[key] = object[key];
      }
    }
    // return the result object
    return result;
  }

  const handleSubmit = async (e) => {
    try {
    // setResponse(''); 
    // get the YAML configuration of GWOCU Studio for this explorer 
    if (!isValidConfiguration(explorer ))
    {
      alert("The GWOCU Studio configuration file is not a valid yaml file.");
      return;
    }
    const yamlObject = await getConfiguration(explorer,apiData.thirdparty)
    console.log("yamlObject",yamlObject);
    //build the headers as found in the API dynamically
    const apiHeaders = parseApiHeaders(apiData);
    // add or replace the global parameters (found in the config) to the headers
    const myheadersWithGlobals = HeadersGlobalAdd(apiHeaders,yamlObject )
    // add or replace the global parameters (found in the config) to the request body
    const myRequestBodyWithGlobals = requestBodyGlobalAdd( apiData.requestBody,yamlObject)

    const finalHeaders = addAuthToHeaders(myheadersWithGlobals,yamlObject );
 
    const finalRequestBody = addAuthToRequestBody(myRequestBodyWithGlobals,yamlObject,crypto);
    // we are using the relay function of our backen to get to the clients API so:

    finalHeaders["destination"] = apiData.urlRoute;

    //  Determine the three mayor parameters of API call based on the authentication case

    

    const allowedMethodsForBody = ["POST", "PUT", "PATCH"]; 
    const fetchOptions = {
      method: apiData.method,
      headers: {
        ...finalHeaders,
      },
    };
    // Check if the current API method allows a body
    if (allowedMethodsForBody.includes(apiData.method.toUpperCase())) {
      fetchOptions.body = JSON.stringify(finalRequestBody);
    }

    
    
    const fetchResponse = await fetch(process.env.REACT_APP_CENTRAL_BACK + "/relay", fetchOptions);
  
    const responseData = await fetchResponse.json();
    const resultWithStatus = {
        status: fetchResponse.status,
        resultBody: responseData
      };

      // save result for eventual workflow use
      const user = JSON.parse(localStorage.getItem("user"));
      const endpoint = `${process.env.REACT_APP_CENTRAL_BACK}/api/registerapiresult`;

      const myresultPayload = {
        result: {...resultWithStatus},
        clientNr: clientNr,
        explorerId: explorerId,
        name: apiData.name,
        email: user.email,
        chatbotKey: user.chatbotKey,
      }
      try {
        await axios.post(endpoint, encodebody(myresultPayload));
      } catch(error) {
        console.log("An error occured when saving result", error);
      }

    return resultWithStatus;

    // return JSON.stringify(responseData, null, 2); // Return the response data
  } catch (error) {
    console.error('Error during API execution:', error);
    throw error; // Throw the error to be caught in the catch block outside the fetch
  }
};

  return (
    <div className="api__details">
      <Tabs value={value} onChange={handleChange} aria-label="API Tabs" className="api-details__tabs">
        <Tab label="Api Basic Information" />
        <Tab label="Payload" />
        <Tab label="Terminal" />
      </Tabs>
      <TabPanel value={value} index={0}>
        <Form>
          <Form.Row className="api-details__save-button-container">
            <Button onClick={updateApi}>Save Changes</Button>
          </Form.Row>
          <Form.Control>
            <Form.Label htmlFor="api-name">API Name</Form.Label>
            <InputText
              id="api-name"
              value={apiData.name}
              onChange={(e) => handleApiDataChange("name", e.target.value)}
              readOnly
            />
          </Form.Control>
          <Form.Control>
            <Form.Label htmlFor="thirdparty">Api Action</Form.Label>
            <Select
              id="thirdparty"
              value={apiData.thirdparty}
              onChange={(e) => handleApiDataChange('thirdparty', e.target.value)}
              options={thirdparties.map((thirdparty) => ({label: thirdparty.name, value: thirdparty.name}))}
            />
          </Form.Control>
          <Form.Control>
            <Form.Label htmlFor="method-select-label">Method</Form.Label>
            <Select
              id="method-select-label"
              value={apiData.method}
              onChange={(e) => handleApiDataChange('method', e.target.value)}
              options={['POST', 'GET', 'PUT', 'DELETE','HEAD','OPTIONS'].map(method => ({label: method, value: method}))}
              />
          </Form.Control>
          <Form.Control>
            <Form.Label htmlFor="url-route">URL Route Example</Form.Label>
            <InputText
              id="url-route"
              value={apiData.urlRoute}
              onChange={(e) => handleApiDataChange('urlRoute', e.target.value)}
            />
          </Form.Control>
          <Form.Control>
            <Form.Label htmlFor="base-url">Base Url</Form.Label>
            <InputText
              id="base-url"
              value={apiData.resourcePath ? apiData.resourcePath : ""}
              onChange={(e) => handleApiDataChange('resourcePath', e.target.value)}
            />
          </Form.Control>
          <Form.Col>
            <TextEditor value={apiData.description} onChange={handleDescriptionChange} />
          </Form.Col>
          <Form.Control className="api-details__headers">
            <Form.Label>Headers</Form.Label>
            {headers.map((header, index) => (
              <Form.Row key={index} className="api-details__header">
                <>
                  <InputText
                    value={header.headerName}
                    onChange={(e) => handleHeaderChange(index, 'headerName', e.target.value)}
                  />
                  <InputText
                    value={header.value}
                    onChange={(e) => handleHeaderChange(index, 'value', e.target.value)}
                  />
                  <Button className="api-details__button-delete-header" color="danger" size="sm" rounded onClick={() => deleteHeader(index)}>
                    <Delete />
                  </Button>
                </>
              </Form.Row>
            ))}
          <Form.Row>
            <Button color="secondary" onClick={addHeader} className="api-details__button-new-header">
              <AddBox />
              New Header
            </Button>
          </Form.Row>
          </Form.Control>
        </Form>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Form>
        <Form.Row className="api-details__save-button-container">
          <Button onClick={updateApi}>Save Changes</Button>
        </Form.Row>
        <Form.Row>
          <Form.Control>
            <Form.Label htmlFor="requestBodyType-select-label">Request Body Type</Form.Label>
            <Select
              id="requestBodyType-select-label"
              value={(apiData.requestBodyType?.toUpperCase() || 'JSON')}
              onChange={(e) => handleApiDataChange('requestBodyType', e.target.value)}
              options={['JSON', 'RAW'].map((type) => ({label: type, value: type}))}
              />
          </Form.Control>
          <Form.Control>
            <Form.Label htmlFor="responseBodyType-select-label">Response Body Type</Form.Label>
            <Select
              id="responseBodyType-select-label"
              value={(apiData.requestBodyType?.toUpperCase() || 'JSON')}
              onChange={(e) => handleApiDataChange('responseBodyType', e.target.value)}
              options={['JSON', 'RAW'].map((type) => ({label: type, value: type}))}
              />
          </Form.Control>
        </Form.Row>
          <Form.Control>
            <Form.Label>Request Body:</Form.Label>
            <CodeEditor
              id='json-editor'
              defaultLanguage="json"
              defaultValue={apiData.requestBody}
              height='300px'
              onChange={handleRequestBodyChange}
              onValidate={validateJson("reqBody")}
              onMount={clearJsonErrors("reqBody")}
              darkMode={isADarkTheme}
            />
          </Form.Control>

          <Form.Control>
            <Form.Label>Response Body Example:</Form.Label>
            <CodeEditor
              id='json-editor3'
              defaultLanguage="json"
              defaultValue={apiData.responseBody}
              height='300px'
              onChange={handleResponseBodyChange}
              onValidate={validateJson("responseBody")}
              onMount={clearJsonErrors("responseBody")}
              darkMode={isADarkTheme}
            />
          </Form.Control>
        
        <Form.Control className="api-details__parameters-editor">
          <Form.Label className="requestbodylabel">Parameters Descriptions:</Form.Label>
          <CodeEditor
            id='json-editor2'
            defaultLanguage="json"
            defaultValue={apiData.parametersDescription}
            height='300px'
            onChange={handleParametersDescriptionChange}
            onValidate={validateJson("paramDesc")}
            onMount={clearJsonErrors("paramDesc")}
            darkMode={isADarkTheme}
          />
        </Form.Control>
        </Form>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Terminal
          commands={commands}
          welcomeMessage={
            <div>
              Welcome to the API execution terminal.
              <br />
              Please type in "run" to submit the API Request. Type "clear" to clear the terminal.
              <br />
            </div>
          }
        />
      </TabPanel>
    </div>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div className="tabpanel-container">
          {children}
        </div>
      )}
    </div>
  );
}

export default APIDetails;

