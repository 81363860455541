import React, { useState, useEffect } from 'react';
import axios from "axios";
import { Button, Icon, Form, InputText, Modal, Textarea, Tooltip } from '../../UI';
import { encodebody, getDecodedBody } from "../../utils/utils.js";
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import yaml from 'js-yaml';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./webhooks.css";

const Webhooks = ({ clientNr, open, onClose }) => {
  const INITIAL_FORM_DATA = {
    clientNr: clientNr,
    name: '',
    url: '',
    webhookKey: '',
    yaml: '',
    successMessageType: 'TEXT',
    successMessage: 'success', 
  };

  const [records, setRecords] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [selectedDefaultYaml, setSelectedDefaultYaml] = useState("");
  const [formData, setFormData] = useState(INITIAL_FORM_DATA);

  useEffect(() => {
    if (!open) return;
    fetchRecords();
    FetchYamlRecord();
  }, [open]);

  useEffect(() => {
    const MyFormdata = {
      ...INITIAL_FORM_DATA,
      yaml: selectedDefaultYaml, // Use the default YAML
    } 
    setFormData(selectedRecord || MyFormdata);
  }, [selectedRecord]);

  const generateWebhookKey = () => {
    return Array.from({ length: 32 }, () =>
      Math.random().toString(36).charAt(2)
    ).join('');
  };

  const updateUrlAndKey = (name) => {
    const webhookKey = generateWebhookKey(); // Generate the webhook key
    const url = `https://apis.gwocu.com/api/webhook/listen/${name}?apiKey=${webhookKey}`; // Add webhookKey as a query parameter with "apiKey" as the key
    setFormData((prevData) => ({
      ...prevData,
      name,
      url,
      webhookKey,
    }));
  };

  const validateMessage = () => {
    const { successMessage, successMessageType } = formData;
    try {
      if (successMessageType === 'JSON') {
        JSON.parse(successMessage); // Validate JSON format
      }
      return true;
    } catch (error) {
      alert('Invalid successMessage for type JSON. Please check your syntax.');
      return false;
    }
  };
  

  const copyToClipboard = (text, label) => {
    navigator.clipboard.writeText(text);
    toast.success(`${label} copied to clipboard!`, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  const FetchYamlRecord = async () => {
    const payloadYaml = { yamlId: "1" };

    try {
      const response = await axios.post(
        process.env.REACT_APP_CENTRAL_BACK + '/yaml/query',
        encodebody(payloadYaml)
      );
      setFormData((prevData) => ({
        ...prevData,
        yaml: getDecodedBody(response.data).yaml,
      }));
      setSelectedDefaultYaml(getDecodedBody(response.data).yaml);
    } catch (error) {
      console.error('Error fetching YAML:', error);
    }
  };

  const fetchRecords = async () => {
    const payload = { clientNr: clientNr };

    try {
      const response = await axios.post(
        process.env.REACT_APP_CENTRAL_BACK + '/webhook/queryall',
        encodebody(payload)
      );
      setRecords(getDecodedBody(response.data));
    } catch (error) {
      console.error('Error fetching records:', error);
    }
  };

  const handleSelectRecord = (record) => {
    setSelectedRecord(prevRecord => record === prevRecord ? null : record);
  };

  const handleAddMode = () => {
    fetchRecords();
    setFormData({
      ...INITIAL_FORM_DATA,
      yaml: selectedDefaultYaml, // Use the default YAML
    });
    setSelectedRecord(null); 
    };


  const handleClose = () => {
    onClose?.();
    setSelectedRecord(null);
    setFormData(INITIAL_FORM_DATA);
  };

  const handleDeleteRecord = async () => {
    if (!selectedRecord) return;
  
    const payload = {
      clientNr: clientNr,
      name: selectedRecord.name,
    };
  
    try {
      await axios.post(
        process.env.REACT_APP_CENTRAL_BACK + '/webhook/delete',
        encodebody(payload)
      );
      await fetchRecords();
      // Reset form to initial state
      setFormData({
        ...INITIAL_FORM_DATA,
        yaml: selectedDefaultYaml, // Use the default YAML
      });
      // Clear selected record
      setSelectedRecord(null);
    } catch (error) {
      console.error('Error deleting record:', error);
    }
  };
  

  const validateYaml = (yamlString) => {
    try {
      yaml.load(yamlString);
      return true;
    } catch (error) {
      alert('Invalid YAML. Please check your YAML syntax.');
      return false;
    }
  };

  const handleUpdateRecord = async () => {
    if (!formData.name || !formData.yaml || !formData.url || !formData.webhookKey) {
      alert('Name, YAML, URL, and Webhook Key fields cannot be empty.');
      return;
    }

    if (!validateMessage()) return;
    if (!validateYaml(formData.yaml)) return;

    try {
      await axios.post(
        process.env.REACT_APP_CENTRAL_BACK + '/webhook/update',
        encodebody(formData)
      );
      fetchRecords();
      handleAddMode();
      // handleClose();
    } catch (error) {
      alert('Error updating Webhook. Please check your fields.');
    }
  };

  const handleAddRecord = async () => {
    if (!formData.name || !formData.yaml || !formData.url || !formData.webhookKey) {
      alert('Name, YAML, URL, and Webhook Key fields cannot be empty.');
      return;
    }

    if (!validateMessage()) return;

    if (!validateYaml(formData.yaml)) return;

    try {
      await axios.post(
        process.env.REACT_APP_CENTRAL_BACK + '/webhook/register',
        encodebody(formData)
      );
      await fetchRecords();
      setFormData({
        ...INITIAL_FORM_DATA,
        yaml: selectedDefaultYaml, // Use the default YAML
      });
    } catch (error) {
      alert('Error adding Webhook. Please check your fields.');
    }
  };

  return (
    <Modal open={open} onClose={handleClose} maxWidth="80rem">
      <Modal.Title>Manage Webhooks</Modal.Title>
      <Modal.Body>
        <div className="webhooks__table-container">
          <table>
            <thead>
              <tr>
                <th>Main Client</th>
                <th>Name</th>
                <th>URL</th>
                
              </tr>
            </thead>
            <tbody>
              {records.map((record) => (
                <tr
                  key={record._id}
                  className={selectedRecord?._id === record._id ? "webhooks__table__row--selected" : ""}
                  onClick={() => handleSelectRecord(record)}
                >
                  <td>{record.clientNr}</td>
                  <td>{record.name}</td>
                  <td>{record.url}</td>
                  
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="divider"></div>
        <Form>
          <Form.Control>
            <Form.Label>Webhook Name:</Form.Label>
            <InputText
              value={formData.name}
              onChange={(e) => updateUrlAndKey(e.target.value)}
            />
          </Form.Control>
          <Form.Control>
            <Form.Label>
              URL:
              <Icon
                onClick={() => copyToClipboard(formData.url, 'URL')}
                style={{ cursor: 'pointer', marginLeft: '8px' }}
                title="Copy URL"
              >
                <ContentCopyIcon />
              </Icon>
            </Form.Label>
            <InputText value={formData.url} readOnly />
          </Form.Control>
          <Form.Control>
            <Form.Label>
              Webhook Key:
              <Icon
                onClick={() => copyToClipboard(formData.webhookKey, 'Webhook Key')}
                style={{ cursor: 'pointer', marginLeft: '8px' }}
                title="Copy Webhook Key"
              >
                <ContentCopyIcon />
              </Icon>
            </Form.Label>
            <InputText value={formData.webhookKey} readOnly />
          </Form.Control>

          <Form.Control>
            <Form.Label>Success Message Type:</Form.Label>
            <select
              value={formData.successMessageType}
              onChange={(e) =>
                setFormData({ ...formData, successMessageType: e.target.value })
              }
            >
              <option value="TEXT">TEXT</option>
              <option value="JSON">JSON</option>
              <option value="HTML">HTML</option>
            </select>
          </Form.Control>
          <Form.Control>
            <Form.Label>Success Message:</Form.Label>
            <Textarea
              value={formData.successMessage}
              onChange={(e) =>
                setFormData({ ...formData, successMessage: e.target.value })
              }
              rows="3"
            />
          </Form.Control>

          <Form.Control>
            <Form.Label>YAML:</Form.Label>
            <Textarea
              value={formData.yaml}
              onChange={(e) => setFormData({ ...formData, yaml: e.target.value })}
              rows="5"
            />
          </Form.Control>
          <Form.Row>
          <Button color="secondary" onClick={handleClose}>
            Cancel
          </Button>
          {selectedRecord && (
            <Button onClick={handleAddMode}>New Webhook</Button>
          )}
          {selectedRecord ? (
            <>
              <Button color="danger" onClick={handleDeleteRecord}>
                Delete
              </Button>
              <Button onClick={handleUpdateRecord}>Update</Button>
            </>
          ) : (
            <Button onClick={handleAddRecord}>Add Webhook</Button>
          )}
        </Form.Row>

        </Form>
      </Modal.Body>
      <ToastContainer />
    </Modal>
  );
};

export default Webhooks;
