import React, { createContext, forwardRef, useContext, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { useOutsideClick } from "../hooks/useOutsideClick";
import { KeyboardArrowRight } from "@material-ui/icons";

const StyledMenu = styled.span`
  display: flex;
  flex-direction: column;
  position: relative;
  width: fit-content;

  &.place-nested-menus-to-right {
    align-items: flex-start;
  }

  &.place-nested-menus-to-left {
    align-items: flex-end;
  }

  &.place-nested-menus-to-right .menu__items {
    left: 0;
  }

  &.place-nested-menus-to-left .menu__items {
    right: 0;
  }

  &.open .menu__items {
    display: block;
  }

  &.place-nested-menus-to-right .menu__nested-menu .menu__items {
    left: 100%;
  }

  &.place-nested-menus-to-left .menu__nested-menu .menu__items {
    right: 100%;
  }

  &.place-nested-menus-to-left .menu__nested-menu-chevron-icon {
    transform: rotate(180deg);
  }

  & .menu__menu-handler svg {
    font-size: 1.5em;
    vertical-align: middle;
  }

   
`;

const StyledMenuItems = styled.ul`
  display: none;
  list-style: none;
  position: absolute;
  top: 100%;
  background-color: var(--menu-bg-clr);
  color: var(--menu-txt-clr);
  border-radius: 0.6rem;
  z-index: var(--z-index-menu, 3000);
  min-width: max-content;
  box-shadow: var(--menu-box-shadow);

  & > .menu__item:first-of-type,
  & > li:first-of-type > .menu__item {
    border-top-left-radius: 0.8rem;
    border-top-right-radius: 0.8rem;
  }

  & > .menu__item:last-of-type,
  & > li:last-of-type > .menu__item {
    border-bottom-left-radius: 0.8rem;
    border-bottom-right-radius: 0.8rem;
  }
  &.scrollable-submenu {
    max-height: 400px !important;
    overflow-y: auto !important;
  }
`;

const StyledMenuItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ $centered }) => $centered ? "center" : "flex-start"};
  gap: 0.5em;
  height: 4rem;
  padding: 0 0.8rem;
  background-color: ${({ $selected }) => $selected ? "var(--menu-bg-clr-hover)" : "revert"};

  & svg {
    font-size: 1.25em;
    vertical-align: middle;
    color: var(--menu-icon-clr);
  }

  &:hover {
    ${({ $type }) => {
      if ($type === "generic") {
        return css`
          cursor: default;
        `;
      } else {
        return css`
          background-color: var(--menu-bg-clr-hover);
          color: var(--menu-txt-clr-hover);
          cursor: pointer;
        `;
      }
    }}
  }

  &:hover > svg {
    color: var(--menu-icon-clr-hover);
  }
`;

const StyledNestedMenu = styled(StyledMenuItem).attrs({ as: "li" })`
  position: relative;

  &.menu__item {
    padding: 0;
  }

  &.menu__item .menu__nested-menu-handler {
    flex: 1;
    padding: 0 0.8rem;
  }

  &.menu__item > .menu__items {
    display: none;
    position: absolute;
    top: 0;
    list-style: none;
    z-index: var(--z-index-menu, 3000);
    min-width: max-content;
  }

  &:hover > .menu__items {
    display: block;
  }
`;

const StyledNestedMenuHandler = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5em;

  & .menu__nested-menu-chevron-icon {
    margin-left: auto;
  }
`;

const StyledDivider = styled.hr`
  border: none;
  border-top: 1px solid var(--menu-border-clr);
  margin: 0.25em 0.5em;
`;

const MenuContext = createContext();

/**
 * @typedef {Object} MenuProps
 * @property {React.ReactNode} props.children - The content to be rendered inside the menu. Can have `Menu.Handler` or `Menu.List` as children.
 * @property {"left" | "right"} [props.nestedMenuPlacement="right"] - If set to `"left"`, nested menus will be rendered on the left-hand side of their parent menu.
 * Default value: `"right"`.
 */

/**
 * GWOCU's menu component
 *
 * This component serves as a container for the entire menu.
 * Can have `Menu.Handler` or `Menu.List` as direct children.
 *
 * @component
 * @param {Object} props - The props for the menu component.
 * @param {React.ReactNode} props.children - The content to be rendered inside the menu. Can have `Menu.Handler` or `Menu.List` as children.
 * @param {"left" | "right"} [props.nestedMenuPlacement="right"] - If set to `"left"`, nested menus will be rendered on the left-hand side of their parent menu.
 * Default value: `"right"`.
 * @returns {JSX.Element} The rendered menu component.
 *
 * @example
 * <Menu>
 *   <Menu.Handler>Open</Menu.Handler>
 *   <Menu.List>
 *     <Menu.Item type="heading">Centered title</Menu.Item>
 *     <Menu.Item>Clickable menu item</Menu.Item>
 *     <Menu.Divider />
 *     <Menu.Item type="generic">Some text</Menu.Item>
 *     <Menu.NestedMenu>
 *       <Menu.NestedMenuHandler>Show nested menu</Menu.NestedMenuHandler>
 *       <Menu.List>
 *         <Menu.Item>Nested menu item 1</Menu.Item>
 *         <Menu.Item>Nested menu item 2</Menu.Item>
 *       </Menu.List>
 *     </Menu.NestedMenu>
 *   </Menu.List>
 * </Menu>
 */
export const Menu = ({children, nestedMenuPlacement}) => {
  const [open, setOpen] = useState(false);
  const menuRef = useOutsideClick(() => setOpen(false));

  const toggleMenu = () => setOpen((prevValue) => !prevValue)
  const closeMenu = () => setOpen(false);

  const classes = `menu place-nested-menus-to-${nestedMenuPlacement || "right"} ${open ? "open" : ""}`;

  return (
    <MenuContext.Provider value={{ toggleMenu, closeMenu }}>
      <StyledMenu
        ref={menuRef}
        className={classes} role="menu" tabIndex={-1}>
        {children}
      </StyledMenu>
    </MenuContext.Provider>
  );
};

/**
 * GWOCU's menu handler component.
 *
 * This component is a slot for any element to handle opening and closing the menu.
 *
 * You can put any interactive element (e.g., a button, icon, etc.) inside this handler.
 * The state of whether the menu is open or closed is managed internally.
 *
 * @memberof Menu
 * @param {Object} props - The props for the menu handler component.
 * @param {React.ReactNode} props.children - The content inside the menu handler, typically a button or icon.
 * @returns {JSX.Element} The rendered menu handler component.
 */
Menu.Handler = ({children}) => {
  const { toggleMenu } = useContext(MenuContext);
  const handleClick = () => {
    toggleMenu();
  };

  return (
    <span
      onClick={handleClick}
      aria-haspopup="true"
      className="menu__menu-handler"
    >
      {children}
    </span>
  );
};

/**
 * GWOCU's menu list component.
 *
 * It should be used within the `Menu` or `Menu.NestedMenu` components. It can have `Menu.Item`, `Menu.NestedMenu`, and `Menu.Divider` as direct children.
 *
 * @memberof Menu
 * @param {Object} props - The props for the menu list component.
 * @param {React.ReactNode} props.children - The direct children of the `Menu.List`. This can include `Menu.Item`, `Menu.NestedMenu` and `Menu.Divider components.
 * @returns {JSX.Element} The rendered menu list component.
 */
Menu.List = ({ children, className }) => {
  return (
    <StyledMenuItems className={`menu__items ${className || ""}`}>
      {children}
    </StyledMenuItems>
  );
};


/**
 * GWOCU's menu item component.
 *
 * This component represents an item in a menu and should be used inside a `Menu.List` component.
 * Depending on the `type` prop, the item has different styles and behaviors:
 * - `"button"`: Displays a pointer cursor, highlights on hover, and automatically closes the menu when clicked.
 * - `"generic"`: A slot for custom content such as text, images, or other buttons. Displays the default cursor and does not automatically close the menu unless focus is shifted away (e.g., by clicking a link).
 *
 * If an `href` is provided, the item will be rendered as an `<a>` tag with the corresponding `href` attribute.
 *
 * @memberof Menu
 * @param {Object} props - The props for the menu item component.
 * @param {string} [props.centered=false] - If `true`, horizontally centers the content. Default value: `false`.
 * @param {React.ReactNode} props.children - The content inside the menu item.
 * @param {string} [props.className=undefined] - Adds a custom className for the menu item. Default value: `undefined`.
 * @param {string} [props.href=undefined] - The URL to navigate to when the item is clicked. If provided, the item is rendered as an `<a>` tag. Default value: `undefined`.
 * @param {string} [props.id=undefined] - The id of the menu item. Default value: `undefined`.
 * @param {function} [props.onClick=undefined] - The `click` event handler. Default value: `undefined`.
 * @param {string} [props.rel="noopener noreferrer"] - The rel attribute for links, applicable when `href` is provided. Default value: `"noopener noreferrer"`.
 * @param {boolean} [props.selected=false] - The id of the menu item. Default value: `false`.
 * @param {string} [props.target="_blank"] - The target attribute for links, applicable when `href` is provided. Default value: `"_blank"`.
 * @param {"button" | "generic"} [props.type="button"] - The type of the menu item. `"button"` items close the menu on click, while `"generic"` items do not. Default value: `"button"`.
 * @returns {JSX.Element} The rendered menu item component.
 *
 * @example
 * <Menu>
 *   <Menu.Handler>Toggle Menu</Menu.Handler>
 *   <Menu.List>
 *     <Menu.Item type="generic">Menu section title</Menu.Item>
 *     <Menu.Item href="https://studio.gwocu.com">Open an external link</Menu.Item>
 *     <Menu.Item onClick={openModal}>Open a modal</Menu.Item>
 *   </Menu.List>
 * </Menu>
 */

Menu.Item = ({
  className,
  type = "button",
  children,
  onClick,
  id,
  href,
  centered,
  selected = false,
  target = "_blank",
  rel = "noopener noreferrer",
}) => {

  const { closeMenu } = useContext(MenuContext);
  const classes = `menu__item ${className || ""}`;

  const handleClick = (e) => {
    e.stopPropagation();
    if (type === "button") closeMenu();
    onClick?.(e);
  };

  return (
    <li role="menuitem" tabIndex={0} id={id} onClick={handleClick}>
      {href ? (
        <StyledMenuItem
          $centered={centered}
          className={classes}
          $type="button"
          as="a"
          href={href}
          target={target}
          rel={rel}
        >
          {children}
        </StyledMenuItem>
      ) : (
        <StyledMenuItem $centered={centered} className={classes} $type={type} onClick={handleClick} $selected={selected}>
          {children}
        </StyledMenuItem>
      )}
    </li>
  );
};

/**
 * GWOCU's nested menu component.
 *
 * This component is used to create nested menus. It should be used inside a `Menu.List` component and can have `Menu.NestedMenuHandler` and `Menu.List` as direct children.
 *
 * @memberof Menu
 * @param {Object} props - The props for the nested menu component.
 * @param {React.ReactNode} props.children - The content inside the nested menu. Should include a `Menu.NestedMenuHandler` and a `Menu.List`.
 * @returns {JSX.Element} The rendered nested menu component.
 */
Menu.NestedMenu = ({ children }) => {
  return (
    <StyledNestedMenu
      className="menu__item menu__nested-menu"
      $type="button"
      tabIndex={0}
    >
      {children}
    </StyledNestedMenu>
  );
};

/**
 * GWOCU's nested menu handler component.
 *
 * This component is a special kind of `Menu.Item` used to trigger the display of a nested menu within a `Menu.List`.
 *
 * It should be placed inside a `Menu.NestedMenu` component.
 *
 * @memberof Menu
 * @param {Object} props - The props for the nested menu handler component.
 * @param {React.ReactNode} props.children - The content inside the nested menu handler.
 * @param {function} [props.onClick=undefined] - The `click` event handler. Default value: `undefined`.
 * @returns {JSX.Element} The rendered nested menu handler component.
 */
Menu.NestedMenuHandler = ({ children, onClick }) => {
  return (
    <StyledNestedMenuHandler
      className="menu__nested-menu-handler"
      onClick={onClick}
    >
      {children}
      <KeyboardArrowRight className="menu__nested-menu-chevron-icon" />
    </StyledNestedMenuHandler>
  );
};

/**
 * GWOCU's menu divider component
 *
 * The `Menu.Divider` is a self-closing component used to visually separate items in a menu.
 *
 * It should be used within a `Menu.List` to separate different groups of `Menu.Item`s.
 *
 * @memberof Menu
 * @returns {JSX.Element} The rendered menu divider component.
 */
Menu.Divider = () => {
  return <StyledDivider className="menu__divider" />;
};


Menu.displayName = "Menu";
Menu.Handler.displayName = "Handler";
Menu.List.displayName = "List";
Menu.Item.displayName = "Item";
Menu.NestedMenu.displayName = "NestedMenu";
Menu.NestedMenuHandler.displayName = "NestedMenuHandler";
Menu.Divider.displayName = "Divider";