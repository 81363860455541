import { FileUploadOutlined } from "@mui/icons-material";
import { useState } from "react";
import styled, { css } from "styled-components";
import { StyledButton } from "./Button";

const sizeStyles = ({ $size = "md" }) => {
  switch ($size) {
    case "xs":
      return css`
        font-size: 1.6rem;
        padding: 0.4rem 0.8rem;
      `;
    case "sm":
      return css`
        font-size: 1.6rem;
        padding: 0.6rem 1.2rem;
      `;
    case "md":
      return css`
        font-size: 1.6rem;
        padding: 0.8rem 1.2rem;
      `;
    case "lg":
      return css`
        font-size: 2rem;
        padding: 1.2rem 2.4rem;
      `;
    default:
      return css``;
  }
};

const StyledLabel = styled(StyledButton).attrs({as: "label"})`
  font-weight: 700;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  display: flex;
  overflow: hidden;

  ${sizeStyles}
`;

const StyledInput = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

/**
 * GWOCU's file input component. styled as a button.
 *
 * This is a file input, but it shares appearance and most of the props of the `Button` component from the library.
 *
 * By default, it accepts all file types (`accept=""`).
 *
 * The `chooseLabel` prop sets the initial button text, which changes to the selected file's name or the number of files if multiple are chosen.
 *
 * @component
 * @param {Object} props - The component's props.
 * @param {string} [props.accept=""] - Specifies the types of files that the input accepts. Default value: `""` (all file types).
 * @param {"xs" | "sm" | "md" | "lg"} [props.buttonSize="md"] - The size of the button. Default value: `"md"`.
 * @param {string} [props.className=undefined] - Adds a custom `className` for the input. Default value: `undefined`.
 * @param {string} [props.chooseLabel="Choose a file"] - The text displayed before a file is selected. Default value: `"Choose a file"`.
 * @param {"primary" | "secondary" | "danger"} [props.color="primary"] - The color of the button. Default value: `"primary"`.
 * @param {boolean} [props.disabled=false] - If `true`, the file input is disabled. Default value: `false`.
 * @param {boolean} [props.fullWidth=false] - If `true`, the button takes up the full width of its container. Default value: `false`.
 * @param {string} [props.id=undefined] - The `id` of the file input. Default value: `undefined`.
 * @param {boolean} [props.multiple=false] - If `true`, allows selecting multiple files. Default value: `false`.
 * @param {string} [props.name=undefined] - The `name` of the file input. Default value: `undefined`.
 * @param {function} [props.onChange=undefined] - The `change` event handler. Default value: `undefined`.
 * @param {boolean} [props.required=false] - If `true`, the file input is required. Default value: `false`.
 * @param {boolean} [props.rounded=false] - If `true`, the button has a circular shape. Default value: `false`.
 * @returns {JSX.Element} The rendered file input component styled as a button.
 *
 * @example
 * <FileInput
 *   accept=".jpg, .jpeg, .png"
 *   color="secondary"
 *   size="lg"
 *   chooseLabel="Choose an image"
 *   onChange={changeHandler}
 * />
 */
export const FileInput = ({
  accept = "",
  className,
  disabled = false,
  id,
  chooseLabel = "Choose a file",
  multiple = false,
  name,
  onChange,
  required = false,
  buttonSize = "md",
  color = "primary",
  fullWidth = false,
  rounded = false,
}) => {
  const [fileName, setFileName] = useState("");
  const labelClassName = `${disabled ? "disabled" : ""} ${className}`;

  const handleChange = (e) => {
    const files = e.target.files;
    if(!files) {
      setFileName(chooseLabel);
    } else if (files && files.length > 1) {
      setFileName(`${files.length} files selected`);
    } else {
      setFileName(e.target.value.split("\\").pop());
    }

    onChange?.(e)
  };

  return (
    <StyledLabel
      htmlFor={id}
      disabled={disabled}
      $size={buttonSize}
      color={color}
      $fullWidth={fullWidth}
      $rounded={rounded}
      className={labelClassName}
      >
      <StyledInput
        type="file"
        name={name}
        id={id}
        multiple={multiple}
        accept={accept}
        onChange={handleChange}
        required={required}
        disabled={disabled}
      />
      <FileUploadOutlined />
      <span>{fileName || chooseLabel}</span>
    </StyledLabel>
  );
};
