import React, { useState } from "react";
import axios from "axios";
import { FetchErrorView } from "../fetchErrorView/FetchErrorView.jsx";
import { Button, Form, Icon, InputText, Select, TextEditor, Toolbar } from "../../UI";
import { useAsync } from "../../hooks/useAsync.js";
import {encodebody, getDecodedBody} from "../../utils/utils.js";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";

const statusOptions = ["Private","Public"];

function Productview({ clientNr, explorerId, productName, authorization, updateTreeView }) {
  const [product, setProduct] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(statusOptions[0]);
  const { loading, error, retrying, retry } = useAsync(fetchProduct, [clientNr, explorerId, productName]);

  const handleDescriptionChange = (value) => {
    setProduct((prevProduct) => ({
      ...prevProduct,
      description: value,
    }));
  };

  const handleSequenceChange = (event) => {
    const inputValue = event.target.value;

    // Check if the input is a valid number
    if (/^\d+$/.test(inputValue) || inputValue === "") {
      // If it's a valid number or an empty string, update the state
      setProduct((prevProduct) => ({
        ...prevProduct,
        sequence: inputValue,
      }));
    }
    // If it's not a valid number, you can choose to do nothing or provide feedback to the user
    // For example, show an error message or prevent further action
  };

  const handleUpdate = async () => {
    const apiUrl = process.env.REACT_APP_CENTRAL_BACK + '/product/update';

    // Define the request body
    const requestBody = {
      clientNr: clientNr,
      explorerId: explorerId,
      productName:product.productName,
      sequence: product.sequence,
      description: product.description,
      status: selectedStatus
    };

      await axios.post(apiUrl, encodebody(requestBody));
      alert("Product was succesfully updated.");
  };

  const handleDelete = async () => {
    const apiUrl = process.env.REACT_APP_CENTRAL_BACK + "/product/delete";

    // Define the request body
    const requestBody = {
      clientNr: clientNr,
      explorerId: explorerId,
      productName:product.productName,
    };

      await axios.post(apiUrl, encodebody(requestBody));
      alert("Product was succesfully removed.");
      updateTreeView();
  };

  async function fetchProduct() {
    if(!productName) return;
    const apiUrl = `${process.env.REACT_APP_CENTRAL_BACK}/product/query`;

    // Define the request body
    const requestBody = {
      clientNr,
      explorerId,
      productName,
    };

    try {
      // Make a POST request to fetch the product
      const response = await axios.post(apiUrl, encodebody(requestBody), {
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = getDecodedBody(response.data);
      // Set the fetched product data to the state
      setProduct(data);
      setSelectedStatus(data.status);
    } catch (error) {
      console.log("Error fetching product:", error);
      throw error;
    }
  };

  return (
      error || retrying
      ? (<FetchErrorView
            className="section-right__content"
            retry={retry}
            retrying={retrying}
          />
        ) : (
          product &&
          <>
            <header className="section-right__toolbar-container">
              <Toolbar>
                {(authorization.designer || authorization.owner) && (
                  <>
                    <Toolbar.Button onClick={handleUpdate}>Update</Toolbar.Button>
                    <Toolbar.Item>
                      <Button color="danger" size="sm" onClick={handleDelete}>Remove</Button>
                    </Toolbar.Item>
                  </>
                )}
                <Toolbar.Item>
                  <Icon href="https://wiki.gwocu.com/en/GWOCU-Studio/products-detail-panel">
                    <HelpCenterIcon />
                  </Icon>
                </Toolbar.Item>
              </Toolbar>
            </header>
            <div className="section-right__content">
              <Form className="section-right__form">
                <Form.Control>
                  <Form.Label htmlFor="productName">
                    Product Name
                  </Form.Label>
                  <InputText
                    id="productName"
                    value={product?.productName}
                    loading={loading}
                    disabled
                  />
                </Form.Control>
                <Form.Control>
                  <Form.Label htmlFor="status">
                    Status
                  </Form.Label>
                  <Select
                    id="status"
                    value={selectedStatus}
                    onChange={(e) => setSelectedStatus(e.target.value)}
                    disabled={!authorization.designer && !authorization.owner }
                    options={statusOptions.map((type) => ({label: type, value: type}))}
                    loading={loading}
                  />
                </Form.Control>
                <Form.Control>
                  <Form.Label htmlFor="sequence">Sequence</Form.Label>
                  <InputText
                    id="sequence"
                    value={product?.sequence}
                    onChange={handleSequenceChange}
                    disabled={!authorization.designer && !authorization.owner}
                    loading={loading}
                  />
                </Form.Control>
                <Form.Control>
                  <Form.Label htmlFor="productDescription">Description</Form.Label>
                  <TextEditor
                    value={product?.description}
                    onChange={handleDescriptionChange}
                    readOnly={!authorization.designer && !authorization.owner}
                    loading={loading}
                  />
                </Form.Control>
              </Form>
            </div>
          </>
        )
  );
}

export default Productview;