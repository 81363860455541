import { useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { useTheme } from "../../context/ThemeContext";
import { Menu } from "../../UI";
import { themes } from "../../themes";
import "./topbar.css";

export default function Topbar() {
  const history = useHistory();
  const {theme: currentTheme, switchTheme } = useTheme();

  function handleClick() {
    const confirmed = window.confirm(
      `Are you sure you want to sign out. Please make sure you have your credentials at hand in case you want to sign in again!`
    );

    if (confirmed) {
        localStorage.removeItem("user");
        history.push('/');
        window.location.reload(true);
        //history.go(0);
    }
  }

  const { user } = useContext(AuthContext);
  const PF = process.env.REACT_APP_PUBLIC_FOLDER;

  const handleThemeSwitch = (dataTheme) => () => {
    if(dataTheme === currentTheme) return;
    switchTheme(dataTheme)
  }

  return (
    <nav className="navbar">
      <Link to="/" className="navbar__brand">
        <div className="navbar__brand-logo">
          {user?.clientNr} GWOCU Studio:
          <span className="navbar__brand-explorer-id">{user?.explorerId}</span>
        </div>
      </Link>
      <ul className="navbar__items">
        <li className="navbar__item">
          <button onClick={handleClick} className="navbar__item-button">
            Sign Out
          </button>
        </li>
        <li className="navbar__item">
          <a
            target="_blank"
            className="navbar__item-button"
            href="https://wiki.gwocu.com/en/GWOCU-Studio/GWOCU-Studio"
          >
            Wiki
          </a>
        </li>
        <li className="navbar__item">
          <Menu>
            <Menu.Handler>
              <button className="navbar__item-button">
                Theme
              </button>
            </Menu.Handler>
            <Menu.List>
              {themes.map(({dataTheme, name, Icon}) => (
                <Menu.Item
                  selected={currentTheme === dataTheme}
                  key={dataTheme}
                  onClick={handleThemeSwitch(dataTheme)}
                  >
                    <Icon />
                    {name}
                </Menu.Item>)
              )}
            </Menu.List>
          </Menu>
        </li>
        <li className="navbar__item navbar__item--profile">
          {user.username === "public@gwocu.com"
            ? (<span className="navbar__item-text">{user.username}</span>)
            : (<Link to="/updateuser/" className="navbar__item-button">{user.username}</Link>)
          }
        </li>
      </ul>
    </nav>
  );
}
